import React, { useState, useEffect, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { Link } from "react-router-dom";
import { getFilesAndCards, uploadFile } from '../api';
import fileIcon from '../static/file.png';
import './FileUpload.css';
import AnkiExportButton from './AnkiExportButton';


function FileChooser({ setSelectedFiles, startChat }) {
    const [files, setFiles] = useState([]);
    const [selectedFileIds, setSelectedFileIds] = useState([]);
    const [notification, setNotification] = useState(null);

    useEffect(() => {
        getFilesAndCards()
            .then(response => setFiles(response.data.files))
            .catch(error => console.error("Error fetching data: ", error));
    }, []);

    const toggleFileSelection = fileId => {
        setSelectedFileIds(prev =>
            prev.includes(fileId) ? prev.filter(id => id !== fileId) : [...prev, fileId]
        );
    };

    useEffect(() => {
        setSelectedFiles(selectedFileIds);
    }, [selectedFileIds, setSelectedFiles]);

    const onDrop = useCallback((acceptedFiles) => {
        const file = acceptedFiles[0];
        uploadFile(file)
            .then(response => {
                debugger
                if (response.data.warning) {
                    setNotification({ type: 'warning', message: response.data.warning });
                    return;
                }
                if (response.data.error) {
                    setNotification({ type: 'error', message: response.data.error });
                    return;
                }
                setFiles([...files, response.data]);
            })
            .catch(error => {
                console.error("Error uploading file: ", error);
                setNotification({ type: 'error', message: 'Error uploading file.' });
            });
    }, [files]);

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        multiple: false,
        accept: 'application/pdf'
    });

    return (
        <div className="filechooser-container">
            {/* <h2>Practice your lectures</h2> */}
            <div className='files-old-and-new'>
                {notification && (
                    <div className={`notification ${notification.type}`}>
                        {notification.message}
                    </div>
                )}
                {files?.length !== 0 && <div className="existing-files">
                    <span>Select files to practice/export:</span>
                    <div className="filechooser-grid">
                        {files.map((file, index) => (
                            <div
                                className={`filechooser-item ${file.cards?.length > 0 ? '' : 'not-processed'}`}
                                onClick={() => file.cards?.length > 0 && toggleFileSelection(file.id)}
                                key={index}
                            >
                                <div className='flex flex-row items-start'>
                                    <img src={fileIcon} alt="file-icon" className="filechooser-icon" />
                                    <input
                                        type="checkbox"
                                        checked={selectedFileIds.includes(file.id)}
                                    />
                                </div>
                                <a className="filechooser-filename">
                                    <Link to={`/file/${file.id}`}>{!file.cards?.length > 0 ? <span>Processing...</span> : <>{file.filename}</>}</Link>
                                </a>
                            </div>
                        ))}
                    </div>
                </div>}
                <div {...getRootProps()} className="dropzone">
                    <input {...getInputProps()} />
                    <p>Drag & Drop or Click to add lectures</p>
                </div>
            </div>
            <div className='fileupload-buttons-start'>
                <button disabled={selectedFileIds.length === 0} onClick={startChat}>
                    Practice Online 🚀
                </button>
                <AnkiExportButton file_ids={selectedFileIds} />
            </div>
        </div>
    );
}

export default FileChooser;

import React, { useState, useEffect } from 'react';
import { getCards, submitCard } from '../api';
import { useLocation } from 'react-router-dom';
import "./Practice.css";

export function Card({ card, cardIndex, cardsCount, handleSubmission, showAnswer, setShowAnswer, nextCard }) {

    
    const averageColor = () => {
        const colors = card.submission_attempts.map(attempt => attempt.color);
        const colorScores = colors.map(color => (color === "green" ? 2 : color === "yellow" ? 1 : 0));
        const averageScore = colorScores.reduce((a, b) => a + b, 0) / (colorScores.length || 1);
        return averageScore > 1.5 ? "green" : averageScore > 0.5 ? "yellow" : "red";
    };

    return (
        <div className='anki-card'>
            <p className='anki-paragraph'>Card {cardIndex+1}/{cardsCount}, you know it: <span style={{'border-radius': "5px", 'padding': '2px'}} className={'anki-' + averageColor()}>{averageColor()}</span></p>
            <div className='anki-card-text'>
                <div className='anki-question'><strong>Question: </strong>{card.question}</div>
                <div className={showAnswer ? "" : "anki-spoiler"}>Answer: {card.answer}</div>
            </div>
            <div className='anki-buttons'>
                <button className='anki-button' onClick={() => setShowAnswer(!showAnswer)}>Reveal Answer</button>
                <button className='anki-button anki-red' onClick={() => handleSubmission("red")}>Bad</button>
                <button className='anki-button anki-yellow' onClick={() => handleSubmission("yellow")}>Ok</button>
                <button className='anki-button anki-green' onClick={() => handleSubmission("green")}>Great</button>
                <button className='anki-button' onClick={() => nextCard()}>Next Card</button>
            </div>
        </div>
    );
}

function Practice() {
    const [cards, setCards] = useState([]);
    const [currentCardIndex, setCurrentCardIndex] = useState(0);
    const [showAnswer, setShowAnswer] = useState(false);
    const location = useLocation();

    useEffect(() => {
        const fetchCards = async () => {
            try {
                const response = await getCards(new URLSearchParams(location.search).get('file_ids'));
                setCards(response.data);
            } catch (error) {
                console.error("Error fetching data: ", error);
            }
        };
        fetchCards();
    }, [location.search]);

    const handleSubmission = (color) => {
        submitCard(cards[currentCardIndex].id, color)
            .then(response => {
                console.log("Submission successful: ", response.data);
                nextCard();
            })
            .catch(error => {
                console.error("Error submitting data: ", error);
            })
    };

    const nextCard = () => {
        setShowAnswer(false);
        setCurrentCardIndex((prevIndex) => (prevIndex + 1) % cards?.length || 1);
    };

    return (
        <div className='anki-container'>
            <div className='anki-items'>
                <h2 className='anki-title'>Practice Your Files</h2>
                {cards?.length || 0 > 0 ? (
                    <Card
                        card={cards[currentCardIndex]}
                        cardIndex={currentCardIndex}
                        cardsCount={cards?.length}
                        handleSubmission={handleSubmission}
                        showAnswer={showAnswer}
                        setShowAnswer={setShowAnswer}
                        nextCard={nextCard}
                    />
                ) : (
                    <p className='anki-paragraph'>Loading cards...</p>
                )}
            </div>
        </div>
    );
}
export default Practice;


import axios from "axios";
import { useState } from "react";
import api from "../api";
import "./Login.css";
import { GoogleLogin } from '@react-oauth/google';
import { useGoogleLogin } from '@react-oauth/google';


export const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const backendGoogleLoginURL = process.env.REACT_APP_API_URL + "/start-google-login/";
  //
  const submit = async e => {
    e.preventDefault();

    const user = {
      username: username,
      password: password
    };

    await api.post('/token/', user, {
      headers: {
        'Content-Type': 'application/json'
      }
    }, { withCredentials: true }).then(response => {
      const { data } = response;
      localStorage.clear();
      localStorage.setItem('access_token', data.access);
      localStorage.setItem('refresh_token', data.refresh);
      axios.defaults.headers.common['Authorization'] = `Bearer ${data['access']}`;
      window.location.href = '/'
    }).catch(err => {
      console.log(err);
    });
  }

  const login = useGoogleLogin({
    flow: 'auth-code',
    onSuccess: async tokenResponse => {
      await api.post('/google/', {
        code: tokenResponse.code
      }, {
        headers: {
          'Content-Type': 'application/json'
        }
      }, { withCredentials: true }).then(response => {
        const { data } = response;
        localStorage.clear();
        localStorage.setItem('access_token', data.access);
        localStorage.setItem('refresh_token', data.refresh);
        axios.defaults.headers.common['Authorization'] = `Bearer ${data['access']}`;
        window.location.href = '/'
      }).catch(err => {
        console.log(err);
      });
    },
  });
  

  return (
    <div className="Auth-form-container">
      <form className="Auth-form" onSubmit={submit}>
        <div className="Auth-form-content">
          <h3 className="Auth-form-title">Sign In</h3>
          Just press this button:
          {/* <div className="form-group mt-3">
            <label>Username</label>
            <input
              className="form-control mt-1"
              placeholder="Enter Username"
              name='username'
              type='text'
              value={username}
              required
              onChange={e => setUsername(e.target.value)}
            />
          </div>
          <div className="form-group mt-3">
            <label>Password</label>
            <input
              name='password'
              type="password"
              className="form-control mt-1"
              placeholder="Enter password"
              value={password}
              required
              onChange={e => setPassword(e.target.value)}
            />
          </div> */}
          <div className="d-grid gap-2 mt-3">
            {/* <button type="submit" className="btn btn-primary">
              Submit
            </button> */}
            <button onClick={() => login()}>
              Sign in with Google 🚀{' '}
            </button>
          </div>
        </div>
      </form>
    </div>
  )
}

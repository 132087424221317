import React, { useState, useEffect } from 'react';
import { getCards, submitCard } from '../api';
import { useLocation } from 'react-router-dom';
import "./Practice.css";

export function MemoryCard({ card, cardIndex, cardsCount, handleSubmission }) {
    const [showAnswer, setShowAnswer] = useState(false);
    
    useEffect(() => {
        // Find and set the current card based on currentCardId
        setShowAnswer(false);
    }, [card]);


    const handleSubmissionAndClear = (color) => {
        handleSubmission(color);
        setShowAnswer(false);
    }

    return (
        <div className='anki-card'>
            <div className='anki-card-text'>
                <div className='anki-question'><strong>Question: </strong>{card.question}</div>
                <div className={showAnswer ? "" : "anki-spoiler"}>Answer: {card.answer}</div>
            </div>
            <div className='anki-buttons'>
                <button className='anki-button' onClick={() => setShowAnswer(!showAnswer)}>Reveal Answer</button>
                <button className='anki-button anki-red' onClick={() => handleSubmissionAndClear("red")}>Bad</button>
                <button className='anki-button anki-yellow' onClick={() => handleSubmissionAndClear("yellow")}>Ok</button>
                <button className='anki-button anki-green' onClick={() => handleSubmissionAndClear("green")}>Great</button>
            </div>
        </div>
    );
}


import React, { useState, useEffect } from 'react';
import FileChooser from './FileChooser';
import "./ChatWithTutor.css";
import api, { getFilesAndCards, setSessionCardID } from '../api';
import { MemoryCard } from './MemoryPractice';
import golem from '../static/golem.png';
import { Timeline } from './Timeline';
import { getCards, submitCardWithSession, getUserDetails } from '../api';
import { useLocation } from 'react-router-dom';
import "./Practice.css";
import { Report } from "./Report";
import { AudioRecorder } from './AudioRecorder';
import { loadStripe } from '@stripe/stripe-js';
import { useNavigate } from "react-router-dom";



function ChatWithTutor() {
    const [messages, setMessages] = useState([]);
    const [userMessage, setUserMessage] = useState("");
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [isFilesChosen, setIsFilesChosen] = useState(false);
    const [fileName, setFileName] = useState(""); // To store the name of the selected file
    const [existingFiles, setExistingFiles] = useState([]); // To store existing files
    const [showDropdown, setShowDropdown] = useState(false); // To control the display of the dropdown
    const [confirmFile, setConfirmFile] = useState(null); // To store the file waiting for confirmation
    const [aiIsTyping, setAiIsTyping] = useState(false); // New state to track if AI is "typing"
    const [showCard, setShowCard] = useState(false); // New state to toggle card display
    const [sessionData, setSessionData] = useState(null);
    const [currentCard, setCurrentCard] = useState(null);
    const messagesEndRef = React.useRef(null);
    const [practiceMode, setPracticeMode] = useState('Memory'); // or 'Memory'
    const [showReport, setShowReport] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        const getUserPromise = getUserDetails();
        if (!getUserPromise) {
            navigate("/login");
            return;
        }
    }, []);

    const setMessagesSafe = (data, currentCardNew) => {
        const realCurrentCard = currentCard ?? currentCardNew;
        if (data.length === 0 && realCurrentCard) {
            data = [{ text: realCurrentCard.question, role: "teacher" }];
        }
        setMessages(data);
    }


    const toggleMode = (event) => {
        setPracticeMode(event.target.value);
    };

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    const fileInputRef = React.createRef();

    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    useEffect(() => {
        // Fetch existing files when the component mounts
        getFilesAndCards()
            .then(response => {
                setExistingFiles(response.data.files);
            })
            .catch(error => {
                console.error("Error fetching data: ", error);
            });
    }, []);

    useEffect(() => {
        // Find and set the current card based on currentCardId
        if (sessionData?.current_card) {
            setCurrentCard(sessionData.current_card);
        }
    }, [sessionData]);

    const handleAudioRecorded = async (audioBase64, send = false) => {
        if (send && audioBase64) {
            // Send the audio message to the server
            setAiIsTyping(true); // Set AI as typing when sending a message
            try {
                const response = await api.post('/api/chat-with-tutor/', {
                    audio: audioBase64,
                    session_id: sessionData.id,
                    file_ids: selectedFiles,
                }).then((response) => {
                    setMessagesSafe(response.data.session.current_card.messages); // Update curr
                    setSessionData(response.data.session);
                    playReceivedAudio(response.data.audio);
                    setAiIsTyping(false); // Set AI as typing when sending a message
                });
            } catch (error) {
                console.error("Error sending audio message: ", error);
            }
        }
    };

    const playReceivedAudio = (audioBase64) => {
        const audioBlob = new Blob([new Uint8Array([...atob(audioBase64)].map(char => char.charCodeAt(0)))], { type: 'audio/wav' });
        const audioUrl = URL.createObjectURL(audioBlob);
        const audio = new Audio(audioUrl);
        audio.play();
    };

    const sendFileMessage = async (file) => {
        try {
            let response;
            if (file.id) { // Existing file
                response = await api.post('/api/chat-with-tutor/', { new_message_file_id: file.id, session_id: sessionData.id, file_ids: selectedFiles })
                    .then((response) => {
                        setSelectedFiles([...selectedFiles, file.id]);
                        setMessagesSafe(response.data.session.current_card.messages); // Update curr
                        setFileName("");
                        setSessionData(response.data.session);
                    });
            } else { // New file
                const formData = new FormData();
                formData.append('file', file);
                formData.append('session_id', sessionData.id);
                response = await api.post('/api/chat-with-tutor/', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                }).then((response) => {
                    setMessagesSafe(response.data.session.current_card.messages); // Update curr
                    setFileName(file.filename || file.name);
                    setSessionData(response.data.session);
                });
            }

        } catch (error) {
            console.error("Error sending file: ", error);
        }
    };

    const sendMessage = async () => {
        const newMessage = { text: userMessage, role: 'user' };
        setMessagesSafe(prev => [...prev, newMessage]);  // Optimistically add message to UI
        setUserMessage("");  // Clear the input
        setAiIsTyping(true); // Set AI as typing when sending a message
        try {
            const response = await api.post('/api/chat-with-tutor/', { message: userMessage, session_id: sessionData.id, file_ids: selectedFiles });
            setCurrentCard(response.data.current_card); // Update curr
            setMessagesSafe(response.data.session.current_card.messages); // Update curr
            setSessionData(response.data.session);
        } catch (error) {
            console.error("Error sending message: ", error);
            setMessagesSafe(prev => prev.filter(m => m !== newMessage));  // Remove the optimistically added message
            setUserMessage(newMessage.text);  // Restore the input
        } finally {
            setAiIsTyping(false); // Reset AI typing state whether message is successful or fails
        }
    }

    const confirmSendFile = () => {
        if (confirmFile) {
            sendFileMessage(confirmFile);
            setConfirmFile(null);
        }
    };

    const handleFileSelect = (file) => {
        setConfirmFile(file);
        setShowDropdown(false);
    };

    const handleFileChange = (event) => {
        if (event.target.files.length > 0) {
            const file = event.target.files[0];
            handleFileSelect(file);
        }
    };
    const handleKeyDown = (event) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault();
            sendMessage();
        }
    };

    const startChat = async () => {
        setIsFilesChosen(true);
        setAiIsTyping(true); // Set AI as typing when sending a message
        try {
            const response = await api.post('/api/start/', { file_ids: selectedFiles }).then(
                response => {
                    setCurrentCard(response.data.session.current_card);
                    setMessagesSafe(response.data.session.current_card.messages, response.data.session.current_card)
                    setSessionData(response.data.session);
                }
            );
        } catch (error) {
            console.error("Error sending message: ", error);
            setMessagesSafe(prev => [...prev, { text: "Error starting the session. Please try again.", role: 'teacher' }]);
        } finally {
            setAiIsTyping(false); // Set AI as typing when sending a message
        }
    };

    const handleSetSessionCardID = (cardId) => {
        setSessionCardID(sessionData.id, cardId).then(response => {
            setSessionData(response.data.session);
            setCurrentCard(response.data.session.current_card)
            setMessagesSafe(response.data.session.current_card.messages, response.data.session.current_card);
        });
    }

    const handleSubmission = (color) => {
        submitCardWithSession(sessionData.id, selectedFiles, color)
            .then(response => {
                setCurrentCard(response.data.session.current_card); // Update curr
                setMessagesSafe(response.data.session.current_card.messages); // Update curr
                setSessionData(response.data.session);
            })
            .catch(error => {
                console.error("Error submitting data: ", error);
            })
    };

    const pickCard = (direction) => {
        // Ensure sessionData and milestones are available
        if (!sessionData || !sessionData.milestones) return;

        // Flatten all cards from milestones
        const allCards = sessionData.milestones.flatMap(milestone => milestone.cards);

        // Find the index of the current card
        const currentIndex = allCards.findIndex(card => card.id === sessionData.current_card.id);

        // Ensure the current card was found
        if (currentIndex === -1) return;

        // Find the next or previous card's index
        // If direction is 'next', pick the next card; if 'prev', pick the previous card
        const newIndex = direction === 'next'
            ? (currentIndex + 1) % allCards.length
            : (currentIndex - 1 + allCards.length) % allCards.length;

        // Get the new card's ID
        const newCardId = allCards[newIndex].id;

        // Set the new card ID
        handleSetSessionCardID(newCardId);
    };


    if (showReport && sessionData?.milestones) {
        return <Report milestones={sessionData.milestones} />;
    }

    return (
        <>
            <div className='full-container'>
                <h1 className="chat-title">AI-Powered Flashcards</h1>
                <div className='small-container'>
                    <div className="chat-page">
                        <div>
                            {!isFilesChosen ? (
                                <FileChooser setSelectedFiles={setSelectedFiles} startChat={startChat} />
                            ) : (
                                <>
                                    <div className="chat-container">

                                        {practiceMode === 'Understanding' ? <><img src={golem} alt="file-icon" className="fileupload-icon" />
                                            <div className="messages">
                                                {messages.map((msg, index) => (
                                                    <div key={index} className={msg.role}>
                                                        {msg.text}
                                                    </div>
                                                ))}
                                                {aiIsTyping && (
                                                    <div className="ai-typing">
                                                        {/* Your loading animation or "typing" message here */}
                                                        Tutor is typing...
                                                    </div>
                                                )}
                                                <div ref={messagesEndRef}></div> {/* This empty div will be scrolled into view */}
                                            </div>
                                            <div className="input-area">
                                                <textarea
                                                    className='input-text-area'
                                                    value={userMessage}
                                                    onChange={(e) => setUserMessage(e.target.value)}
                                                    onKeyDown={handleKeyDown}
                                                    placeholder="Type your message here..."
                                                />
                                                <AudioRecorder onRecorded={handleAudioRecorded} />
                                                <button onClick={sendMessage}>Send</button>
                                                <button
                                                    onClick={() => setShowDropdown(!showDropdown)}
                                                    className="pin-button"
                                                >
                                                    📎 {fileName}
                                                </button>
                                                {showDropdown && (
                                                    <div className="file-dropdown">
                                                        {existingFiles.map(file => (
                                                            <p key={file.id} onClick={() => handleFileSelect(file)}>{file.filename}</p>
                                                        ))}
                                                        <p onClick={() => fileInputRef.current.click()}>Attach new file</p>
                                                    </div>
                                                )}
                                                <input
                                                    type="file"
                                                    onChange={handleFileChange}
                                                    style={{ display: 'none' }}
                                                    ref={fileInputRef}
                                                />
                                            </div>
                                            {confirmFile && (
                                                <div className="confirm-file">
                                                    <p>Send file: {confirmFile.filename}?</p>
                                                    <button onClick={confirmSendFile}>Confirm</button>
                                                    <button onClick={() => setConfirmFile(null)}>Cancel</button>
                                                </div>
                                            )}</> : <>
                                            {currentCard && <MemoryCard
                                                card={currentCard}
                                                cardIndex={0}
                                                cardsCount={1}
                                                handleSubmission={(color) => handleSubmission(color)}
                                                showAnswer={false}
                                                setShowAnswer={null}
                                            />}
                                        </>}
                                    </div>
                                </>
                            )}
                            {currentCard && <div className='bottom'>
                                {/* <div className='bottom-left'>
                                    <div>
                                        <label>
                                            <input
                                                type="radio"
                                                value="Memory"
                                                checked={practiceMode === 'Memory'}
                                                onChange={toggleMode}
                                            />
                                            Memory
                                        </label>
                                        <label>
                                            <input
                                                type="radio"
                                                value="Understanding"
                                                checked={practiceMode === 'Understanding'}
                                                onChange={toggleMode}
                                            />
                                            Understanding
                                        </label>
                                    </div>
                                </div> */}
                                <div className='bottom-right'>
                                    <button onClick={() => pickCard('back')}>← Back</button>
                                    <button onClick={() => pickCard('next')}>→ Next</button>
                                </div>
                                <button onClick={() => setShowReport(!showReport)}>
                                    Go to report
                                </button>
                            </div>}
                        </div>
                    </div>
                    <div className="timeline-container">
                        {sessionData && (
                            <Timeline
                                milestones={sessionData.milestones}
                                currentCardId={sessionData.current_card.id}
                                showCard={showCard}
                                setShowCard={setShowCard}
                                setSessionCardID={handleSetSessionCardID}
                            />
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}
export default ChatWithTutor;

// AudioRecorder.js
import React, { useState } from 'react';
import "./AudioRecorder.css";


export function AudioRecorder({ onRecorded }) {
    const [mediaRecorder, setMediaRecorder] = useState(null);
    const [isRecording, setIsRecording] = useState(false);
    const [audioUrl, setAudioUrl] = useState(null);

    const startRecording = async () => {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        const recorder = new MediaRecorder(stream);
        let audioChunks = [];

        recorder.ondataavailable = event => {
            audioChunks.push(event.data);
        };

        recorder.onstop = () => {
            const audioBlob = new Blob(audioChunks, { type: 'audio/wav' });
            setAudioUrl(URL.createObjectURL(audioBlob)); // Set the audio URL for playback
            const reader = new FileReader();
            reader.readAsDataURL(audioBlob);
            reader.onloadend = () => {
                const base64data = reader.result.split(',')[1];
                onRecorded(base64data, () => setAudioUrl(null)); // Clear the audio URL after sending
            };
        };

        setMediaRecorder(recorder);
        recorder.start();
        setIsRecording(true);
    };

    const stopRecording = () => {
        mediaRecorder.stop();
        setIsRecording(false);
    };

    return (
        <div>
            {!isRecording ? (
                <button className='button-record' onClick={startRecording}>Record 🔴 </button>
            ) : (
                <button className='button-send-recording' onClick={stopRecording}>Send 🟨</button>
            )}
        </div>
    );
}

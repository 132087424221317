// Report.js
import React from 'react';
import './Report.css';

export function Report({ milestones }) {
    const allCards = milestones.flatMap(milestone => milestone.cards);
    const attemptedCards = allCards.filter(card => card.submission_attempts.length > 0);

    const totalAttempts = attemptedCards.reduce((acc, card) => acc + card.submission_attempts.length, 0);
    const successfulAttempts = attemptedCards.reduce((acc, card) => acc + card.submission_attempts.filter(attempt => attempt.color === 'green').length, 0);
    const failedAttempts = totalAttempts - successfulAttempts;

    const getEncouragementMessage = () => {
        if (successfulAttempts / totalAttempts > 0.8) {
            return "Amazing work! You're mastering this material!";
        } else if (successfulAttempts / totalAttempts > 0.5) {
            return "Great job! A little more practice and you'll have it down!";
        } else {
            return "Keep going! Every attempt is a step towards understanding!";
        }
    };

    return (
        <div className="report">
            <h2>Report Summary</h2>
            <p className="encouragement">{getEncouragementMessage()}</p>
            <div className="statistics">
                <p>Total Attempts: {totalAttempts}</p>
                <p>Successful Attempts: {successfulAttempts}</p>
                <p>Failed Attempts: {failedAttempts}</p>
            </div>
            <h3>Card Details:</h3>
            <ul>
                {attemptedCards.map(card => (
                    <li key={card.id}>
                        Card {card.id}: {card.submission_attempts.filter(attempt => attempt.color === 'green').length} successful, {card.submission_attempts.filter(attempt => attempt.color !== 'green').length} failed
                        {card.messages && card.messages.length > 0 && <span> - Challenging</span>}
                    </li>
                ))}
            </ul>
        </div>
    );
}

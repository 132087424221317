import React, { useState, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom";
import Practice from './components/Practice';
import { Login } from './components/Login';
import { Logout } from './components/Logout';
import ChatWithTutor from './components/ChatWithTutor';
import FileView from "./components/FileView";
import "./App.css";
import { GoogleOAuthProvider } from '@react-oauth/google';
import api, { getUserDetails } from './api';
import Profile from "./components/Profile"
import TheNewLandingPage from './components/LandingPage2';


function App() {
  const [isAuth, setIsAuth] = useState(false);
  useEffect(() => {
    if (localStorage.getItem('access_token') !== null) {
      setIsAuth(true);
    }
  }, [isAuth]);

  return (
      <GoogleOAuthProvider clientId="288544535479-83oe1a4sgdg9a0u1l4hbkddrud9nbqas.apps.googleusercontent.com">
        <Router>
          <div className='nav'>
            <Link to="/">Main</Link><br />
            <Link to="/study/">Study</Link><br />
            <Link to="/profile/">Payments</Link><br />
            {/* Add conditional rendering for Login/Logout based on isAuth */}
            {isAuth ? <Link to="/logout">Logout</Link> : <Link to="/login">Login</Link>}
          </div>
          <Routes>
            <Route path="/practice/" element={<Practice />} />
            <Route path="/profile/" element={<Profile />} />
            <Route path="/" element={<TheNewLandingPage />} />
            <Route path="/study" element={<ChatWithTutor />} />
            <Route path="/login" element={<Login />} />
            <Route path="/logout" element={<Logout />} />
            <Route path="/file/:fileId" element={<FileView />} />
            <Route path="*" element={<div>404 Not Found</div>} />
          </Routes>
        </Router>
      </GoogleOAuthProvider>
  );
}

export default App;

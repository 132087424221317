import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { useNavigate, useLocation } from "react-router-dom";
import { getUserDetails, postStripeCheckout } from "../api";
import "./Profile.css";

const PLAN_DETAILS = {
    'basic': { 'token_limit': 50000, 'price': 4.99 },
    'standard': { 'token_limit': 100000, 'price': 9.99 },
    'premium': { 'token_limit': 250000, 'price': 19.99 },
};

const testAPIKey = "pk_test_OYlORFw16VVltfYkMOrtvdM900NWyDSpXm";
const publicStripeKey = process.env.REACT_APP_SHOULD_USE_STRIPE_TEST_MODE === "false" ? process.env.REACT_APP_PUBLIC_STRIPE_API_KEY : testAPIKey;
const stripePromise = loadStripe(publicStripeKey);

function Profile() {
    const [user, setUser] = useState(null);
    const navigate = useNavigate();
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const status = query.get('status');

    useEffect(() => {
        getUserDetails().then(response => {
            setUser(response.data);
        }).catch(err => {
            console.log(err);
        });
    }, []);

    const handleCheckout = async (planKey) => {
        const response = postStripeCheckout({ selected_plan: planKey }).then(async session => {
            const stripe = await stripePromise;
            const result = await stripe.redirectToCheckout({
                sessionId: session.data.id
            });
            if (result.error) {
                alert(result.error.message);
            }
        });
    };

    if (user === null) {
        return <div className="loading">Loading...</div>;
    }

    return (
        <section className="w-screen min-h-screen bg-[#FAEBD7] flex flex-col items-center justify-center p-6">
            <h1 className="text-4xl font-bold mb-6 text-black">Choose Your Plan</h1>
            <p className="text-xl mb-4">Current Plan: {user.plan.name.charAt(0).toUpperCase() + user.plan.name.slice(1)}</p>
            <p className="text-xl mb-4">Current Token Balance: {user.plan.token_limit - user.plan.tokens_used}, ({((user.plan.token_limit - user.plan.tokens_used)/2000).toFixed(0)} pages)</p>
            
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                {Object.keys(PLAN_DETAILS).map(planKey => {
                    const plan = PLAN_DETAILS[planKey];
                    return (
                        <div key={planKey} className="bg-white rounded-lg shadow-md p-6">
                            <h2 className="text-2xl font-semibold mb-2 text-black">{planKey.charAt(0).toUpperCase() + planKey.slice(1)}</h2>
                            <p className="text-gray-700 mb-4">Tokens: {plan.token_limit}</p>
                            <p className="text-gray-700 mb-4">Pages: ~{((plan.token_limit / 2000).toFixed(0))} pages of lectures</p>
                            <p className="text-gray-700 mb-4">{plan.description}</p>
                            <p className="text-black text-3xl font-bold mb-6">${plan.price}/mo</p>
                            <button onClick={() => handleCheckout(planKey)} class="hover:bg-blue-500 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded">
                                Choose Plan
                            </button>
                        </div>
                    );
                })}
            </div>
        </section>
    )
}

export default Profile;

function Button({ children, className = '', ...props }) {
    return (
        <button className={`px-5 py-2 rounded-lg ${className}`} {...props}>
            {children}
        </button>
    );
}

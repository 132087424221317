export function Timeline({ milestones, currentCardId, setSessionCardID }) {
    const renderAttempts = (attempts) => {
        return (
            <div className="attempts">
                {attempts.map((attempt, index) => (
                    <div key={index} className={`stick ${attempt.color}`}></div>
                ))}
            </div>
        );
    };

    // Flatten the cards from milestones into a single array
    const allCards = milestones.flatMap(milestone => milestone.cards);

    return (
        <div className="timeline">
            {allCards.map((card, index) => (
                <div 
                    key={card.id} 
                    className={`card ${card.id === currentCardId ? 'current' : ''}`} 
                    onClick={() => setSessionCardID(card.id)}
                    >
                    <div>Card {card.id}</div>
                    {renderAttempts(card.submission_attempts)}
                </div>
            ))}
        </div>
    );
}
import React from 'react';
import api from '../api';

function AnkiExportButton({ file_ids }) {
    const handleExportClick = async () => {
        try {
            const response = await api.post('/api/download_as_anki/', {
                file_ids: file_ids.map(f => f.id)
            }, {
                responseType: 'blob',
                headers: {
                    'Content-Type': 'application/json',
                    // Include other headers like authentication tokens if needed
                }
            });

            const blob = new Blob([response.data], { type: 'application/octet-stream' });
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = 'exported_deck.apkg';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(url);
        } catch (error) {
            console.error("An error occurred while exporting the Anki deck:", error);
        }
    };

    return (
        <button disabled={file_ids.length === 0} onClick={handleExportClick}>
            Export as Anki Deck
        </button>
    );
}

export default AnkiExportButton;

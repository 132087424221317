import React from 'react';
// import './CardPreview.css';

export function CardPreview({ card, cardIndex, cardsCount }) {
    const averageColor = () => {
        const colors = card.submission_attempts.map(attempt => attempt.color);
        const colorScores = colors.map(color => (color === "green" ? 2 : color === "yellow" ? 1 : 0));
        const averageScore = colorScores.reduce((a, b) => a + b, 0) / (colorScores.length || 1);
        return averageScore > 1.5 ? "green" : averageScore > 0.5 ? "yellow" : "red";
    };

    return (
        <div className='anki-card-preview'>
            <p className='anki-paragraph-preview'>Card {cardIndex+1}/{cardsCount}, knowledge: <span style={{'border-radius': "5px", 'padding': '2px'}} className={'anki-' + averageColor()}>{averageColor()}</span></p>
            <div className='anki-card-text-preview'>
                <div className='anki-question-preview'><strong>Q:</strong>{card.question}</div>
                <div className='anki-answer-preview'>A: {card.answer}</div>
            </div>
        </div>
    );
}

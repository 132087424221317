import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import api, { getFilesAndCards } from '../api';
import { CardPreview } from './CardPreview';
import "./FileView.css";

function FileView() {
    const { fileId } = useParams();
    const [file, setFile] = useState(null);
    const [cards, setCards] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [cardsPerPage, setCardsPerPage] = useState(5); // or whatever number you prefer

    useEffect(() => {
        getFilesAndCards()
            .then(response => {
                let file = response.data.files.filter(file => file.id.toString() === fileId)[0];
                setFile(file);
                setCards(file.cards);
            })
            .catch(error => {
                console.error("Error fetching data: ", error);
            });
    }, [fileId]);

    const indexOfLastCard = currentPage * cardsPerPage;
    const indexOfFirstCard = indexOfLastCard - cardsPerPage;
    const currentCards = cards.slice(indexOfFirstCard, indexOfLastCard);

    const totalPages = Math.ceil(cards.length / cardsPerPage);

    const handlePageChange = (newPage) => {
        if (newPage >= 1 && newPage <= totalPages) {
            setCurrentPage(newPage);
        }
    };

    return (
        <div className="file-view-container">
            <h2>{file?.filename}</h2>
            <div className="cards-grid">
                {currentCards.map((card, index) => (
                    <CardPreview key={index} card={card} cardIndex={index} cardsCount={currentCards.length} />
                ))}
            </div>
            <div className="pagination-controls">
                <button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
                    Previous
                </button>
                <span>Page {currentPage} of {totalPages}</span>
                <button onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>
                    Next
                </button>
            </div>
        </div>
    );
}

export default FileView;

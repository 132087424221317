import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link
} from "react-router-dom";
import { useNavigate } from "react-router-dom";
import img from "../static/Lectures.png";

import React, { useState, useEffect } from 'react';
import { getUserDetails } from "../api";

export default function TheNewLandingPage() {
    const [user, setUser] = useState(null);
    const navigate = useNavigate();


    useEffect(() => {
        const getUserPromise = getUserDetails();
        if (!getUserPromise) {
            return;
        }
        const user = getUserDetails().then(response => {
            setUser(response.data);
        }).catch(err => {
            console.log(err);
        });
    }, []);

    return (
        <div>
            <section className="mt-[2em] sm:mt-0 padding-landing h-1500 dark:bg-gray-800 py-12 md:py-24 lg:py-32">
                <div className="container mx-auto max-w-screen-xl grid items-center gap-4 px-4 md:px-6 lg:grid-cols-2 lg:gap-10">
                    <div className="space-y-3">
                        <h1 className="text-4xl font-bold tracking-tighter text-gray-900 dark:text-white md:text-5xl">
                        Organised: AI-Powered Flashcards
                        </h1>
                        <p className="max-w-[600px] text-gray-600 dark:text-gray-300 md:text-xl/relaxed lg:text-base/relaxed xl:text-xl/relaxed">
                            Turn your textbooks and lectures into effective flashcards using cutting-edge AI technology.
                        </p>
                        {user ? <Link to="/study" style={{ textDecoration: 'none', marginTop: '20px', display: 'inline-block', padding: '10px 20px', background: '#007BFF', color: 'white', borderRadius: '4px' }}>Start for free</Link> : <Link to="/login" style={{ textDecoration: 'none', marginTop: '20px', display: 'inline-block', padding: '10px 20px', background: '#007BFF', color: 'white', borderRadius: '4px' }}>Start for free</Link>}
                    </div>
                    <img
                        alt="Flashcards"
                        className="object-cover bg-white  rounded-lg shadow-lg"
                        src={img}
                        style={{
                            objectFit: "cover",
                        }}
                    />
                </div>
            </section>
            <section className="w-screen h-full bg-blue-100 dark:bg-gray-900 py-12 md:py-24 lg:py-32">
                <div className="container mx-auto max-w-screen-xl grid items-center gap-4 px-4 md:px-6 lg:grid-cols-3 lg:gap-10">
                    <div className="space-y-3">
                        <h2 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                            AI-Powered Flashcard Creation
                        </h2>
                        <p className="text-gray-600 dark:text-gray-300">
                            Our system uses AI to extract key points and turn them into flashcards.
                        </p>
                    </div>
                    <div className="space-y-3">
                        <h2 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Smart Text Recognition</h2>
                        <p className="text-gray-600 dark:text-gray-300">
                            We can recognize text from a variety of sources, including handwritten notes.
                        </p>
                    </div>
                    <div className="space-y-3">
                        <h2 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Easy Organization</h2>
                        <p className="text-gray-600 dark:text-gray-300">Keep all your flashcards organized and accessible.</p>
                    </div>
                </div>
            </section>
        </div>
    );
}

export function Button({ children, className = '', ...props }) {
    return (
        <button className={`px-5 py-2 bg-blue-500 text-white rounded-lg ${className}`} {...props}>
            {children}
        </button>
    );
}

export function Input({ className = '', ...props }) {
    return (
        <input className={`px-2 py-1 border rounded-lg border-gray-300 ${className}`} {...props} />
    );
}
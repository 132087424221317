import axios from "axios";

const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
});

let isRefreshing = false;
let failedQueue = [];

api.interceptors.request.use(
    config => {
        const token = localStorage.getItem('access_token');
        if (token) {
            config.headers['Authorization'] = 'Bearer ' + token;
        }
        return config;
    },
    error => Promise.reject(error)
);


export const getFilesAndCards = () => {
    return api.get('/api/user/files-and-cards/');
};


export const postStripeCheckout = (data) => {
    return api.post('/path_to_create_checkout_session/', data)
};

export const getUserDetails = () => {
    if (localStorage.getItem('access_token')) {
        return api.get('/user/details', {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('access_token')}`
          }
        })
      }
    return null;
}

export const uploadFile = (file) => {
    const formData = new FormData();
    formData.append('file', file);
    return api.post('/api/upload/', formData, {
        headers: {
            'content-type': 'multipart/form-data'
        }
    });
};

export const getCards = (fileIds) => {
    return api.get(`/api/cards/`, {
        params: {
            file_ids: fileIds
        }
    });
};

export const submitCard = (cardId, color) => {
    return api.post(`/api/submission/`, { card: cardId, color: color });
};

export const submitCardWithSession = (sessionId, fileIds, color) => {
    return api.post(`/api/session-submission/`, { session_id: sessionId, color: color, file_ids: fileIds });
};

export const setSessionCardID = (sessionId, cardId) => {
    return api.post(`/api/set-session-id-override/`, { session_id: sessionId, current_card_id_override: cardId});
};

export const loginUser = (username, password) => {
    return api.post('/token/', { username, password }, {
        headers: {
            'Content-Type': 'application/json'
        }
    });
};

export const logoutUser = (refreshToken) => {
    return api.post('/logout/', { refresh_token: refreshToken }, {
        headers: {
            'Content-Type': 'application/json'
        }
    });
};

export const refreshAuthToken = (refreshToken) => {
    return api.post('/token/refresh/', { refresh: refreshToken }, {
        headers: {
            'Content-Type': 'application/json'
        }
    });
};

const processQueue = (error, token = null) => {
    failedQueue.forEach(prom => {
        if (error) {
            prom.reject(error);
        } else {
            prom.resolve(token);
        }
    });
    failedQueue = [];
};


api.interceptors.response.use(
    response => response,
    error => {
        const originalRequest = error.config;
        if (error.response.status === 401 && !originalRequest._retry) {
            if (isRefreshing) {
                return new Promise((resolve, reject) => {
                    failedQueue.push({ resolve, reject });
                })
                    .then(token => {
                        originalRequest.headers['Authorization'] = 'Bearer ' + token;
                        return api(originalRequest);
                    })
                    .catch(err => {
                        return Promise.reject(err);
                    });
            }

            originalRequest._retry = true;
            isRefreshing = true;

            return new Promise((resolve, reject) => {
                api.post('/token/refresh/', {
                    refresh: localStorage.getItem('refresh_token')
                })
                    .then(({ data }) => {
                        localStorage.setItem('access_token', data.access);
                        localStorage.setItem('refresh_token', data.refresh);
                        api.defaults.headers.common['Authorization'] = 'Bearer ' + data.access;
                        originalRequest.headers['Authorization'] = 'Bearer ' + data.access;
                        processQueue(null, data.access);
                        resolve(api(originalRequest));
                    })
                    .catch(err => {
                        processQueue(err, null);
                        reject(err);
                    })
                    .finally(() => {
                        isRefreshing = false;
                    });
            });
        }

        return Promise.reject(error);
    }
);

export default api;
